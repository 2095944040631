import { FC } from 'react';
import { InputBase, InputAdornment, Icon } from '@mui/material';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (event: any) => void;
  width: string;
}

const SearchBar: FC<Props> = ({ onChange, width }) => {
  return (
    <InputBase
      placeholder="Search Collections"
      sx={{
        width: width,
        color: '#fff',
        border: '2px solid #18e0ab',
        borderRadius: '15px',
      }}
      startAdornment={
        <InputAdornment position="start" sx={{ marginLeft: '10px' }}>
          <Icon sx={{ color: '#18e0ab' }}>search</Icon>
        </InputAdornment>
      }
      onChange={onChange}
    ></InputBase>
  );
};

export default SearchBar;
