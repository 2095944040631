import React, { FC } from 'react';
import { Project } from '../data/types';
import Gallery from '../components/Gallery';
import SearchBar from '../components/SearchBar';
import StatusFilter from '../components/StatusFilter';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';

interface Props {
  projects: Project[];
}

const Collections: FC<Props> = (props) => {
  const theme = useTheme();
  const [projects, setProjects] = React.useState(props.projects);
  const [filteredProjects, setFilteredProjects] = React.useState(props.projects);

  let search = '';
  let filter = 'All';
  function onFilterChange() {
    // filter the search
    const p = projects.filter((x: Project) => {
      return x.name.toLowerCase().includes(search.toLowerCase()) && (filter === 'All' || filter === x.status);
    });
    setFilteredProjects(p);
  }

  function onSearch(event: any) {
    search = event.target.value;
    onFilterChange();
  }

  function onFilter(event: any) {
    filter = event.target.value;
    onFilterChange();
  }
  return (
    <div className="project-gallery">
      <div style={{ paddingTop: '80px', width: '80%', margin: 'auto' }}>
        {/* @ts-ignore */}
        {useMediaQuery(theme.breakpoints.up('sm')) ? (
          <div className="project-gallery-header" style={{ marginBottom: '50px' }}>
            <SearchBar onChange={onSearch} width="25%" />
            <h1 className="align-center white project-gallery-title">Collections</h1>
            <StatusFilter onChange={onFilter} width="25%" />
          </div>
        ) : (
          <div>
            <h1 className="align-center white project-gallery-title">Collections</h1>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '10px',
                marginBottom: '20px',
              }}
            >
              <SearchBar onChange={onSearch} width="40%" />
              <StatusFilter onChange={onFilter} width="40%" />
            </div>
          </div>
        )}
        <Gallery
          // @ts-ignore
          items={filteredProjects}
          isProject={true}
        />
      </div>
    </div>
  );
};

export default Collections;
