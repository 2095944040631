import React, { FC } from 'react';
import { Project, Token } from '../data/types';
import { Typography, Grid, Button } from '@mui/material';
import Gallery from '../components/Gallery';
import { getTokensForProject } from '../data/db_interface';
import { DocumentData } from 'firebase/firestore';

interface Props {
  projects: Project[];
  //@ts-ignore
  match: any;
}

class ProjectView extends React.Component<
  Props,
  { projId: number; project: Project; tokens: Token[]; lastDoc: DocumentData | null }
> {
  constructor(props: Props) {
    super(props);
    const projId = parseInt(props.match.params.id);
    const project = props.projects.filter((x) => x.id.toString() === projId.toString())[0];
    this.state = {
      projId,
      project,
      tokens: [],
      lastDoc: null,
    };
    getTokensForProject(projId, 20).then((result) => this.setState({ tokens: result.tokens, lastDoc: result.lastDoc }));
  }
  render() {
    return (
      //@ts-ignore
      <div style={{ paddingTop: '80px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <br />
            <br />
            <br />
            <div style={{ paddingLeft: '20px', paddingRight: '15px' }}>
              <Typography variant="h3" color="primary">
                <strong>{this.state.project?.name}</strong>
              </Typography>
              <Typography variant="h6" color="secondary">
                By {this.state.project?.artistName}
              </Typography>
              <Typography variant="body1" color="primary">
                <strong>Status: {this.state.project?.status}</strong>
              </Typography>
              <Typography variant="body1" color="primary">
                {this.state.project?.description}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={9}>
            <div style={{ paddingRight: '20px', paddingLeft: '20px' }}>
              <Gallery
                // @ts-ignore
                items={this.state.tokens}
                isProject={false}
              />
              <div style={{ textAlign: 'center' }}>
                <Typography
                  style={{ cursor: 'pointer' }}
                  variant="button"
                  color="primary"
                  onClick={() => {
                    if (this.state.lastDoc) {
                      const ldoc = this.state.lastDoc;
                      this.setState({ lastDoc: null });
                      getTokensForProject(this.state.projId, 20, ldoc).then((result) =>
                        this.setState({ tokens: [...this.state.tokens, ...result.tokens], lastDoc: result.lastDoc })
                      );
                    }
                  }}
                >
                  Load More
                </Typography>
              </div>
              <br />
              <br />
              <br />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default ProjectView;
