import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { Link } from 'react-router-dom';
import WalletButton from './WalletButton';
import { IconButton, Menu, MenuItem, Typography, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../img/site-logo-v2.png';

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

function ElevationScroll(props: Props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export default function Navbar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [anchor, setAnchor] = React.useState(null);
  const open = Boolean(anchor);

  const handleMenu = (event: any) => {
    console.log(event.currentTarget);
    setAnchor(event.currentTarget);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ElevationScroll>
        {/**@ts-ignore**/}
        <AppBar color="background">
          <Toolbar>
            <Link
              to="/"
              style={{ paddingRight: '20px', fontSize: 24, fontWeight: 'bold', color: theme.palette.primary.main }}
            >
              <img src={Logo} style={{ height: '40px' }}></img>
            </Link>

            {isMobile ? (
              <Box
                sx={{
                  marginLeft: 'auto',
                  '& button.ant-btn': {
                    color: '#18E0A9 !important',
                    background: '#000 !important',
                    border: '2px solid #18E0A9 !important',
                    borderRadius: '20px',
                    boxShadow: 'none !important',
                  },
                }}
              >
                <WalletButton />
                <IconButton
                  onClick={handleMenu}
                  {...{
                    edge: 'end',
                    color: 'inherit',
                    'aria-label': 'menu',
                    'aria-haspopup': 'true',
                  }}
                  //@ts-ignore
                  color="green"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchor}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  color="background"
                  // keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  onClose={() => setAnchor(null)}
                  open={open}
                  sx={{
                    '& .MuiMenu-list': {
                      padding: 0,
                      opacity: 0.8,
                      backgroundColor: '#000000',
                      borderRadius: 0,
                    },
                  }}
                >
                  <MenuItem onClick={() => setAnchor(null)} component={Link} to="/">
                    {/**@ts-ignore */}
                    <Typography variant="span" color="primary">
                      Home
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={() => setAnchor(null)} component={Link} to="/collections">
                    {/**@ts-ignore */}
                    <Typography variant="span" color="primary">
                      Collections
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={() => setAnchor(null)} component={Link} to="/about">
                    {/**@ts-ignore */}
                    <Typography variant="span" color="primary">
                      About
                    </Typography>
                  </MenuItem>
                </Menu>
              </Box>
            ) : (
              <>
                <span style={{ flexGrow: 1 }}></span>
                <Box
                  sx={{
                    justifyContent: 'flex-end',
                    '& button': {
                      color: '#18E0A9 !important',
                      background: '#000 !important',
                      border: '2px solid #18E0A9 !important',
                      borderRadius: '20px',
                      boxShadow: 'none !important',
                    },
                  }}
                >
                  <Link to="/collections" style={{ paddingRight: '20px' }}>
                    {/**@ts-ignore */}
                    <Typography variant="span" color="primary">
                      Collections
                    </Typography>
                  </Link>
                  <Link to="/about" style={{ paddingRight: '20px' }}>
                    {/**@ts-ignore */}
                    <Typography variant="span" color="primary">
                      About
                    </Typography>
                  </Link>
                  <WalletButton />
                </Box>
              </>
            )}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </Box>
  );
}
