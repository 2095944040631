import { FC } from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (event: any) => void;
  width: string;
}

const StatusFilter: FC<Props> = ({ onChange, width }) => {
  return (
    <FormControl sx={{ width: width }}>
      <InputLabel sx={{ color: '#fff' }}>Status</InputLabel>
      <Select
        label="Status"
        sx={{
          '& fieldset': {
            borderColor: '#18e0ab !important',
            color: '#5a5a5a !important',
          },
          '&:hover fieldset': {
            borderColor: '#18e0ab !important',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#18e0ab',
          },
          '& .MuiSvgIcon-root': {
            color: '#fff',
          },
          '& .MuiSelect-select': {
            color: '#fff',
          },
        }}
        defaultValue={'All'}
        variant="outlined"
        onChange={onChange}
      >
        <MenuItem value={'All'}>All</MenuItem>
        <MenuItem value={'Open'}>Open</MenuItem>
        <MenuItem value={'Paused'}>Paused</MenuItem>
        <MenuItem value={'Complete'}>Complete</MenuItem>
      </Select>
    </FormControl>
  );
};

export default StatusFilter;
