import { Project, Token } from './types';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import {
  collection,
  getDocs,
  where,
  query,
  DocumentReference,
  orderBy,
  startAfter,
  limit,
  QueryDocumentSnapshot,
  DocumentData,
} from 'firebase/firestore';

const firebaseApp = initializeApp({
  apiKey: 'AIzaSyBsCIDKSAEn7K7YnIM-3V3GgWMyZSl5e8Y',
  authDomain: 'genlabs-4363e.firebaseapp.com',
  projectId: 'genlabs-4363e',
  storageBucket: 'genlabs-4363e.appspot.com',
  messagingSenderId: '332593180342',
  appId: '1:332593180342:web:d85d62de117498a62c0017',
});

const db = getFirestore();

export async function getAllProjects(): Promise<Project[]> {
  const querySnapshot = await getDocs(collection(db, 'projects'));
  const projects: Project[] = [];
  querySnapshot.forEach((doc: any) => {
    const proj: Project = {
      id: doc.id,
      ...doc.data(),
    };
    projects.push(proj);
  });
  return projects;
}

export async function getTokensForProject(
  projectId: number,
  limitNum: number,
  startAfterDoc?: DocumentData
): Promise<{ tokens: Token[]; lastDoc: DocumentData }> {
  let q;
  if (startAfterDoc) {
    q = query(
      collection(db, 'tokens'),
      where('projectId', '==', projectId),
      orderBy('tokenId'),
      limit(limitNum),
      startAfter(startAfterDoc)
    );
  } else {
    q = query(collection(db, 'tokens'), where('projectId', '==', projectId), orderBy('tokenId'), limit(limitNum));
  }
  const tokens: Token[] = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc: any) => {
    const token: Token = {
      id: doc.id,
      ...doc.data(),
    };
    tokens.push(token);
  });
  const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
  return { tokens, lastDoc };
}
