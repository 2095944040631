import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter, Route } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import Home from './views/Home';
import Collections from './views/Collections';
import Generative from './views/Generative';
import ProjectView from './views/ProjectView';
import Navbar from './components/Navbar';
import About from './views/About';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { getAllProjects } from './data/db_interface';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ffffff',
      light: '#ffffff',
      dark: '#ffffff',
    },
    secondary: {
      main: '#5a5a5a',
    },
    background: {
      //@ts-ignore
      main: '#000000',
    },
    purple: {
      main: '#CA30F7',
    },
    green: {
      main: '#18E0A9',
    },
    blue: {
      main: '#7C8DFF',
    },
  },
  typography: {
    h3: {
      fontSize: '1.5rem',
    },
  },
});
getAllProjects().then((projects) => {
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <CssBaseline />
        <SnackbarProvider>
          <Navbar />
          <Route exact path="/" component={Home} />
          <Route path="/collections">
            <Collections projects={projects.filter((proj) => proj.public)} />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/project/:id" render={(matchProps) => <ProjectView {...matchProps} projects={projects} />} />
        </SnackbarProvider>
      </BrowserRouter>
    </ThemeProvider>,
    document.getElementById('root')
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
