import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/styles';
import { Link } from 'react-router-dom';

interface Item {
  id: number;
  name: string;
  imageUrl: string;
  tokenId?: number;
  status?: string;
}
interface Props {
  items: Item[];
  isProject: boolean;
}

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  rowGap: '2.5rem',
  columnGap: '1.5rem',
  [theme.breakpoints.only('xs')]: {
    gridTemplateColumns: 'repeat(1,minmax(0,1fr))',
  },
  [theme.breakpoints.only('sm')]: {
    gridTemplateColumns: 'repeat(2,minmax(0,1fr))',
  },
  [theme.breakpoints.only('md')]: {
    gridTemplateColumns: 'repeat(3,minmax(0,1fr))',
  },
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: 'repeat(4,minmax(0,1fr))',
  },
}));

const Gallery: FC<Props> = ({ items, isProject }) => {
  const theme = useTheme();
  return (
    <StyledContainer>
      {items.map((item) => {
        return (
          <div key={item.id} style={{ marginBottom: '65px', marginLeft: '10px', marginRight: '10px' }}>
            {isProject ? (
              <Link
                to={isProject ? `/project/${item.id}` : item.imageUrl}
                style={{ color: '#fff', textDecoration: 'inherit' }}
                className="galleryelem"
              >
                <img
                  src={isProject ? item.imageUrl : `https://cdn.mintinglab.art/mintlab_assets/300/${item.tokenId}.webp`}
                  alt={item.name}
                  style={{ borderRadius: '5%', width: '100%' }}
                />
                <p style={{ fontSize: '1.5em', marginTop: '10px', marginBottom: '0' }}>{item.name}</p>
                {/**@ts-ignore */}
                {isProject ? <p style={{ color: theme.palette.green.main }}>{item.status}</p> : <span></span>}
              </Link>
            ) : (
              <a
                href={isProject ? '/project/' + item.id : item.imageUrl}
                style={{ color: '#fff', textDecoration: 'inherit' }}
                className="galleryelem"
              >
                <img
                  src={isProject ? item.imageUrl : `https://cdn.mintinglab.art/mintlab_assets/300/${item.tokenId}.webp`}
                  alt={item.name}
                  style={{ borderRadius: '5%', width: '100%' }}
                />
                <p style={{ fontSize: '1.5em', marginTop: '10px', marginBottom: '0' }}>{item.name}</p>
                {/**@ts-ignore */}
                {isProject ? <p style={{ color: theme.palette.green.main }}>{item.status}</p> : <span></span>}
              </a>
            )}
          </div>
        );
      })}
    </StyledContainer>
  );
};

export default Gallery;
