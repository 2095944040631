import { FC } from 'react';
import { useTheme } from '@mui/styles';
import { Button, useMediaQuery } from '@mui/material';

const About: FC = () => {
  const theme = useTheme();
  //@ts-ignore
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div
      className="about-page white"
      style={
        isMobile
          ? { paddingTop: '80px', paddingLeft: '15%', paddingRight: '15%' }
          : { paddingTop: '80px', paddingLeft: '25%', paddingRight: '25%' }
      }
    >
      <div className="about-section">
        {/**@ts-ignore */}
        <h1>What is GenLabs?</h1>
        <div className="about-section-content">
          <p>
            GenLabs is a platform focussed on enabling all artists and creators on the Solana blockchain. Currently we
            are providing a launchpad where you can view and purchase NFTs from your favourite creators and collections,
            with plans to offer much more.
          </p>
        </div>
      </div>
      <div className="about-section">
        {/**@ts-ignore */}
        <h1>Minting Process</h1>
        <div className="about-section-content">
          <p>
            Firstly, you can connect your wallet to GenLabs by clicking the Select Wallet button at the top right of
            your screen. Then select your wallet provider, and press Connect. We recommend using Phantom as your wallet
            provider if you don&apos;t have one already.
          </p>
          <p>
            Find a collection that you&apos;re interested in minting. You will only be able to mint projects that are
            currently open. On the collection&apos;s page, there will be a Mint button that can be used when the project
            is open.
          </p>
          <p>
            After you&apos;ve clicked the Mint button, you will need to confirm the transaction with your wallet
            provider. For web extension wallets, a popup will normally appear on your screen where you can confirm the
            transaction.
          </p>
          <p>
            As soon as the transaction is confirmed, you will be able to see your new token in your wallet. If there are
            any issues with the transaction, such as the project has sold out, no SOL will be taken from your wallet.
          </p>
        </div>
      </div>
      <div className="about-section">
        {/**@ts-ignore */}
        <h1>Roadmap</h1>
        <div className="about-section-content">
          Solana is constantly evolving and we aim to implement these constant advancements in our toolset and open new
          ways for artists and creatives to express themselves. We want these tools to be available to everyone that
          wants to be part of the Solana NFT ecosystem.
          <p>Q1 2022</p>
          <ul>
            <li>Release MintLab, the Solana NFT collection launchpad on the GenLabs website.</li>
            <li>
              Build traction for MintLab by onboarding artists and launching a marketing campaign to encourage use of
              the platform.
            </li>
            <li>Launch incentives program for artists</li>
          </ul>
          <p>Q2 2022</p>
          <ul>
            <li>
              Release our NFT marketplace to provide an immediate secondary marketplace for projects launching with
              GenLabs
            </li>
          </ul>
          <p>Q3 2022</p>
          <ul>
            <li>
              Add additional support and features for generative art projects on GenLabs. This includes interactive
              on-chain generative art created at mint time.
            </li>
          </ul>
        </div>
      </div>
      <div className="about-section">
        {/**@ts-ignore */}
        <h1>Listing Process</h1>
        <div className="about-section-content">
          <p>
            To list a project, you can apply using the button below and fill out the Google form. Firstly, we want to
            know about you as an artist, if you have any previous art, or a portfolio. Currently, we are only accepting
            artists that have a prototype for the collection theyre proposing to list. The form also asks for
            information that is specific to your collection.
          </p>
        </div>
        <div className="about-button-wrapper">
          <Button
            variant="outlined"
            //@ts-ignore
            color="green"
            className="home-explore-button"
            href="https://forms.gle/bNUNRC9Po6i3hmABA"
            target="_blank"
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
};

export default About;
