import React, { FC } from 'react';
import { useTheme } from '@mui/styles';
import { Grid, Button } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from 'react-router-dom';
import nftGraphic from '../img/speranza_0.png';
import marketingIcon from '../img/Home-icon01.png';
import securityIcon from '../img/Home-icon02.png';
import targetIcon from '../img/Home-icon03.png';
import twitterIcon from '../img/Twitter.png';
import discordIcon from '../img/Discord.png';

const Home: FC = () => {
  const theme = useTheme();

  return (
    <div
      style={
        //@ts-ignore
        useMediaQuery(theme.breakpoints.up('sm')) ? { paddingTop: '130px' } : { paddingTop: '20px' }
      }
      className="home"
    >
      <section className="home-section" style={{ paddingTop: '0px !important' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <div
              style={
                //@ts-ignore
                useMediaQuery(theme.breakpoints.up('sm')) ? { textAlign: 'right' } : { textAlign: 'center' }
              }
            >
              <img
                src={nftGraphic}
                style={
                  //@ts-ignore
                  useMediaQuery(theme.breakpoints.up('sm'))
                    ? { height: '400px', width: '400px', borderRadius: '15px' }
                    : { height: '300px', width: '300px', borderRadius: '15px' }
                }
              ></img>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div
              style={
                //@ts-ignore
                useMediaQuery(theme.breakpoints.up('sm'))
                  ? { paddingRight: '20px' }
                  : { textAlign: 'center', paddingRight: '5%', paddingLeft: '5%' }
              }
            >
              <span className="home-title font white">
                <strong>
                  {/**@ts-ignore */}
                  <p style={{ color: theme.palette.green.main }} className="nomargin">
                    Collect now
                  </p>
                  <p className="nomargin">with an A-class</p>
                  <p className="nomargin">minting experience</p>
                </strong>
              </span>
              <p className="home-subtitle white">Curated NFT projects with GenLabs</p>
              <Link to="/collections">
                {/**@ts-ignore*/}
                <Button variant="outlined" color="green" className="home-explore-button">
                  Explore Collections
                </Button>
              </Link>
            </div>
          </Grid>
        </Grid>
      </section>
      <section className="home-section">
        <div
          style={
            //@ts-ignore
            useMediaQuery(theme.breakpoints.up('sm')) ? { paddingLeft: '10%', paddingRight: '10%' } : {}
          }
        >
          <strong>
            <p className="home-title align-center white">
              {/**@ts-ignore */}
              We provide streamlined tools and <span style={{ color: theme.palette.green.main }}>make it easy</span> to
              bring creativity to Solana
            </p>
          </strong>
          <p className="home-subtitle align-center white">GenLabs</p>
        </div>
      </section>
      <section className="home-section align-center">
        <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
          <p className="home-title align-center white">
            {/**@ts-ignore */}
            <span style={{ color: theme.palette.blue.main }}>You create,</span> we do the rest
          </p>
          <div className="home-card-row">
            <div className="home-card">
              <div className="home-card-icon align-center">
                <img src={marketingIcon}></img>
              </div>
              <p className="home-card-title align-center">Marketing</p>
              <div className="home-card-text align-center">
                Creating your art with us gives you access to a new audience
              </div>
            </div>
            <div className="home-card">
              <div className="home-card-icon align-center">
                <img src={targetIcon}></img>
              </div>
              <p className="home-card-title align-center">Technology</p>
              <div className="home-card-text align-center">
                We will manage all the technical aspects related to your launch, saving you time and money.
              </div>
            </div>
            <div className="home-card">
              <div className="home-card-icon align-center">
                <img src={securityIcon}></img>
              </div>
              <p className="home-card-title align-center">Security</p>
              <div className="home-card-text align-center">
                Using our tried and tested systems reduces your risk of hacks and scams.
              </div>
            </div>
          </div>
          <Button
            variant="outlined"
            //@ts-ignore
            color="blue"
            className="home-explore-button"
            href="https://forms.gle/bNUNRC9Po6i3hmABA"
            target="_blank"
          >
            Apply
          </Button>
        </div>
      </section>
      <section className="home-section">
        <div className="align-center">
          <h2>Get the Latest News</h2>
          <a href="https://twitter.com/GenLabsArt" className="social-icon" target="_blank" rel="noreferrer">
            <img src={twitterIcon}></img>
          </a>
          <a href="https://discord.gg/genlabs" className="social-icon" target="_blank" rel="noreferrer">
            <img src={discordIcon}></img>
          </a>
        </div>
      </section>
    </div>
  );
};

export default Home;
